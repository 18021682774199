namespace $.$$ {
	$mol_style_define( $mol_book2_catalog, {
		Menu_filter: {
			flex: {
				shrink: 0,
				grow: 0,
			},
			alignSelf: 'stretch',
		},
	})

}
